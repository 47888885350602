// extracted by mini-css-extract-plugin
export var aboutDescription = "services-module--aboutDescription--3pgxO";
export var accordionWrap = "services-module--accordionWrap--N+vik";
export var animateCircle = "services-module--animate-circle--ILNvl";
export var bg = "services-module--bg--YLw5O";
export var bounce = "services-module--bounce--bIoIQ";
export var cardWrap = "services-module--cardWrap--FHaOQ";
export var cardWrapActive = "services-module--cardWrapActive--+wQ0N";
export var hiddenTitle = "services-module--hiddenTitle--araXx";
export var ldsRing = "services-module--lds-ring--qZtQR";
export var serviceTables = "services-module--serviceTables--PfBJE";
export var serviceWhyUs = "services-module--serviceWhyUs--2x7Qz";
export var serviceWhyUsUsItemsBody = "services-module--serviceWhyUsUsItemsBody--pYsuv";
export var wrap = "services-module--wrap--JCvJb";