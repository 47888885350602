// extracted by mini-css-extract-plugin
export var animateCircle = "businesses-module--animate-circle--D6Ypq";
export var bounce = "businesses-module--bounce--rDisA";
export var clientsSection = "businesses-module--clientsSection--3CHz4";
export var factItem = "businesses-module--factItem--vrd2X";
export var factItemDescription = "businesses-module--factItemDescription--q+69d";
export var factItemHidden = "businesses-module--factItemHidden--Iv2rD";
export var factItemNumber = "businesses-module--factItemNumber--95p6o";
export var factItemTitle = "businesses-module--factItemTitle--UquGo";
export var factItemUnitNewLine = "businesses-module--factItemUnitNewLine--DAHEL";
export var factItemUnitWrap = "businesses-module--factItemUnitWrap--t6Idk";
export var factItemUnits = "businesses-module--factItemUnits--+Gn9u";
export var facts = "businesses-module--facts--4w3oo";
export var factsSlider = "businesses-module--factsSlider--YnQHF";
export var hiddenTitles = "businesses-module--hiddenTitles--WSX60";
export var ldsRing = "businesses-module--lds-ring--nvlJE";
export var partners = "businesses-module--partners--q163y";
export var partnersImage = "businesses-module--partnersImage--jW8DA";
export var plates = "businesses-module--plates--lqWqw";
export var slideButton = "businesses-module--slideButton--yyAtF";
export var slideButtonNext = "businesses-module--slideButtonNext--hSPsa";
export var slideButtonPrev = "businesses-module--slideButtonPrev--MmQIY";