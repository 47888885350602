import className from 'classnames'
import * as React from 'react'
import { useState } from 'react'
import * as style from '../../../../../assets/scss/pages/businesses.module.scss'
import keyGenerator from '../../../../utils/keyGenerator'
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import ImagePlates from '../../../reusable/ImagePlates/ImagePlates'

const IndustryPlates = ({ platesData, maxPlates, hoverPlateData, onDark }) => {
  const [activeIndustryItem, setActiveIndustryItem] = useState(0)

  const platesArray = maxPlates ? platesData.slice(0, maxPlates) : platesData
  const nextSlide = () => {
    const next =
      activeIndustryItem === platesArray.length - 1 ? 0 : activeIndustryItem + 1
    setActiveIndustryItem(next)
  }
  const prevSlide = () => {
    const prev =
      activeIndustryItem === 0 ? platesArray.length - 1 : activeIndustryItem - 1
    setActiveIndustryItem(prev)
  }
  return (
    <div className={style.plates}>
      <button
        aria-label="Prevision slide"
        type="button"
        onClick={prevSlide}
        onKeyDown={prevSlide}
        className={className(style.slideButton, style.slideButtonPrev)}
      />
      {platesArray.map((elem, index) => {
        const { image, title, description, link, buttonText } = elem
        return (
          <div
            role="button"
            tabIndex={0}
            key={keyGenerator(index, title)}
            onClick={() => setActiveIndustryItem(index)}
            onKeyDown={() => setActiveIndustryItem(index)}
          >
            <ImagePlates
              active={activeIndustryItem === index}
              link={link}
              description={description}
              buttonText={buttonText}
              title={title}
              image={image}
            />
          </div>
        )
      })}
      {hoverPlateData && (
        <ImagePlates
          onDark={onDark}
          buttonText={hoverPlateData.buttonText}
          link={hoverPlateData.link}
          hoverCard={hoverPlateData.title}
        />
      )}
      <button
        aria-label="Next slide"
        type="button"
        onClick={nextSlide}
        className={className(style.slideButton, style.slideButtonNext)}
      />
    </div>
  )
}

export default IndustryPlates
