import Fade from 'react-reveal/Fade'
import * as React from 'react'
import Section from '../../common/SectionComponent/Section'
import * as hoverPlate from '../HoverPlates/hover-plates.module.scss'
import keyGenerator from '../../../utils/keyGenerator'
import HoverPlate from '../HoverPlates/HoverPlate'

const HoverPlatesSection = ({ title, description, data, isLight }) => (
  <Section isLight={isLight} title={title}>
    <p
      className={
        isLight ? 'text-color__gray--on-light' : 'text-color__gray--on-dark'
      }
    >
      {description}
    </p>
    <Fade>
      <div className={hoverPlate.wrap}>
        {data.businesses.map((elem, index) => {
          const business = elem.business?.business
          business.button.text = elem.overrideLabels.buttonText
          let props = {
            title: elem.title,
            description: elem.description,
          }

          if (business) {
            props = {
              title: business.businessHeader.title,
              description: business.businessHeader.description,
              url: business.button.url.uri,
              image: business.image,
              button: business.button,
            }
          }
          return (
            <div className={hoverPlate.elem} key={keyGenerator(index, 1)}>
              <HoverPlate isLight={isLight} {...props} />
            </div>
          )
        })}
      </div>
    </Fade>
  </Section>
)

export default HoverPlatesSection
