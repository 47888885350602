import * as React from 'react'
import className from 'classnames'
import { Link } from 'gatsby'
import * as style from './Iiage-plates.module.scss'
import ButtonPrimary from '../../common/buttons/ButtonPrimary'

const ImagePlates = ({
  title,
  buttonText,
  description,
  link,
  image,
  isLight,
  onDark,
  active,
  hoverCard,
}) => {
  const ImagePlateWrap = ({ children }) => {
    if (link) {
      if (hoverCard) {
        return (
          <Link
            to={link}
            className={className(
              style.plate,
              style.plateHover,
              onDark && style.plateHoverOndark
            )}
          >
            {children}
          </Link>
        )
      }
      return (
        <Link
          to={link}
          className={className(style.plate, active && style.activePlate)}
        >
          {children}
        </Link>
      )
    }
    if (hoverCard) {
      return (
        <div
          className={className(
            style.plate,
            style.plateHover,
            onDark && style.plateHoverOndark
          )}
        >
          {children}
        </div>
      )
    }
    return (
      <div className={className(style.plate, active && style.activePlate)}>
        {children}
      </div>
    )
  }
  return (
    <>
      {hoverCard ? (
        <ImagePlateWrap>
          <div className={style.content}>
            <p className={style.plateTitle}>{hoverCard}</p>
            <div className={style.hiddenBlock}>
              {buttonText && (
                <ButtonPrimary
                  className={style.hiddenBlockButton}
                  btnClass="button-primary--white"
                  isLight
                >
                  {buttonText}
                </ButtonPrimary>
              )}
            </div>
          </div>
        </ImagePlateWrap>
      ) : (
        <ImagePlateWrap>
          <div
            className={style.image}
            style={{ backgroundImage: `url('${image}')` }}
          />
          <div className={style.content}>
            <p className={style.plateTitle}>{title}</p>
            <div className={style.hiddenBlock}>
              {description && (
                <p className="text-20-caption-text-medium text-color__gray--on-dark">
                  {description}
                </p>
              )}
              {buttonText && (
                <ButtonPrimary isLight={isLight}>{buttonText}</ButtonPrimary>
              )}
            </div>
          </div>
        </ImagePlateWrap>
      )}
    </>
  )
}

export default ImagePlates
