import * as React from 'react'
import Section from '../../../common/SectionComponent/Section'
import IndustryPlates from './IndustryPlates'

const IndustryPlatesSection = ({ data }) => {
  const hoverPlateData = {
    buttonText: 'See more',
    link: '/industries/',
    title: 'Discover more industries',
  }
  const plates = data.industriesSection
    .filter((e) => e.industry)
    .map((e) => {
      const elem = e.industry.industry
      return {
        image: elem?.image.sourceUrl,
        title: elem?.title,
        description: elem.overrideLabels?.description,
        link: `/portfolio/?${elem?.filterBy}=${elem?.industry.slug}`,
        buttonText: e.overrideLabels.buttonText,
      }
    })
  return (
    <Section isLight title={data.industriesHeader.title}>
      <p className="text-color__gray--on-light">
        {data.industriesHeader.description}
      </p>
      <IndustryPlates
        platesData={plates}
        maxPlates={5}
        hoverPlateData={hoverPlateData}
      />
    </Section>
  )
}

export default IndustryPlatesSection
