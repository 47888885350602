// extracted by mini-css-extract-plugin
export var activePlate = "Iiage-plates-module--activePlate--Xw17T";
export var animateCircle = "Iiage-plates-module--animate-circle--OxcCv";
export var bounce = "Iiage-plates-module--bounce--srKzQ";
export var content = "Iiage-plates-module--content--sCb8a";
export var hiddenBlock = "Iiage-plates-module--hiddenBlock--oH5TN";
export var hiddenBlockButton = "Iiage-plates-module--hiddenBlockButton--TCOZL";
export var image = "Iiage-plates-module--image--ATstj";
export var ldsRing = "Iiage-plates-module--lds-ring--hgKC5";
export var plate = "Iiage-plates-module--plate--Z87LB";
export var plateHover = "Iiage-plates-module--plateHover--iCt5B";
export var plateHoverOndark = "Iiage-plates-module--plateHoverOndark--w+ihC";
export var plateTitle = "Iiage-plates-module--plateTitle--aKZqz";