import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import className from 'classnames'
import Fade from 'react-reveal/Fade'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import MainLayout from '../../layouts/MainLayout'
import PageMainSection from '../../components/reusable/PageMainSection/PageMainSection'
import ButtonPrimary from '../../components/common/buttons/ButtonPrimary'
import IndustryPlatesSection from '../../components/Pages/Industry/IndustryPlatesSection/IndustryPlatesSection'
import HoverPlatesSection from '../../components/reusable/HoverPlatesSection/HoverPlatesSection'
import FactsSection from '../../components/Pages/Businesses/FactsSection/FactsSection'
import HoverTextComponentSection from '../../components/reusable/HoverTextComponentSection/HoverTextComponentSection'
import * as style from '../../components/common/SectionComponent/section-component.module.scss'
import * as s from '../../../assets/scss/pages/businesses.module.scss'
import keyGenerator from '../../utils/keyGenerator'

const Services = ({ data, location }) => {
  const seoData = data.wpPage.metaTags
  return (
    <MainLayout seoData={seoData} location={location}>
      <PageMainSection
        title={data.wpPage.page_businesses.title}
        hiddenTitle={seoData.seoHiddenTitle}
        description={data.wpPage.page_businesses.description}
        image="bussiness.png"
        imageMobile="bussiness_mobile.png"
      >
        <ButtonPrimary buttonData={data.wpPage.page_businesses.button} />
      </PageMainSection>
      <HoverPlatesSection
        isLight
        title={data.wpPage.page_businesses.businessesHeader.title}
        description={data.wpPage.page_businesses.businessesHeader.description}
        data={data.wpPage.page_businesses}
      />
      <FactsSection data={data} />
      <IndustryPlatesSection data={data.wpPage.page_businesses} maxPlates={5} />
      <HoverTextComponentSection
        items={data.wpPage.page_businesses.supportingItems}
        title={data.wpPage.page_businesses.suportingHeader.title}
        description={data.wpPage.page_businesses.suportingHeader.description}
        isWithButton
        image="/images/supporting.3e1c358.png"
      />
      <section className={className(style.section, s.clientsSection)}>
        <div className="container">
          <Fade bottom>
            <h2 className={className(style.title, 'text-color--secondary')}>
              {data.wpPage.page_businesses.partnersHeader.title}
            </h2>
          </Fade>
          <div className={style.content}>
            <p className="text-color__gray--on-light">
              {data.wpPage.page_businesses.partnersHeader.description}
            </p>
            <div className={s.partners}>
              {data.wpPage.page_businesses.partnersLogos.map((elem, index) => {
                const img = getImage(elem.localFile)
                return (
                  <div key={keyGenerator(index)} className={s.partnersImage}>
                    <GatsbyImage alt="123" image={img} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  )
}

export const query = graphql`
  {
    wpPage(databaseId: { eq: 42 }) {
      metaTags {
        seoTitle
        seoHiddenTitle
        seoDescription
        seoKeywords
        ogImage {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              resize(width: 1024, height: 512) {
                src
              }
            }
          }
          title
        }
      }
      page_businesses {
        partnersHeader {
          description
          title
        }
        partnersLogos {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        supportingItems {
          title
          description
          button {
            addQuery
            text
            query
            useCustomUrl
            urlCustom
            url {
              ... on WpPost {
                uri
              }
              ... on WpPage {
                uri
              }
            }
          }
        }
        suportingHeader {
          description
          title
        }
        industriesHeader {
          description
          title
        }
        industriesSection {
          overrideLabels {
            buttonText
          }
          industry {
            ... on WpIndustry {
              industry {
                filterBy
                title
                image {
                  sourceUrl
                }
                industry {
                  slug
                  uri
                }
              }
            }
          }
        }
        businessesHeader {
          description
          title
        }
        description
        title
        factItems {
          title
          description
          number
          units
          isUnitNewline
        }
        factsHeader {
          description
          title
        }
        button {
          addQuery
          text
          query
          useCustomUrl
          urlCustom
          url {
            ... on WpPost {
              uri
            }
            ... on WpPage {
              uri
            }
          }
        }
        businesses {
          business {
            ... on WpBusiness {
              menuOrder
              business {
                businessHeader {
                  description
                  title
                }
                button {
                  addQuery
                  text
                  query
                  useCustomUrl
                  urlCustom
                  url {
                    ... on WpPost {
                      uri
                    }
                    ... on WpPage {
                      uri
                    }
                  }
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
          overrideLabels {
            buttonText
          }
        }
      }
    }
  }
`

Services.propTypes = {
  data: PropTypes.shape({
    wpPage: PropTypes.shape({
      page_businesses: PropTypes.shape({
        title: PropTypes.string,
        hiddenTitle: PropTypes.string,
        description: PropTypes.string,
        suportingHeader: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        }),
        partnersHeader: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        }),
        button: PropTypes.shape({
          text: PropTypes.string,
          query: PropTypes.string,
        }),
        businessesHeader: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        }),
        businesses: PropTypes.arrayOf(PropTypes.object),
        industriesHeader: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        }),
        supportingItems: PropTypes.arrayOf(PropTypes.object),
      }),
      metaTags: PropTypes.shape({
        seoHiddenTitle: PropTypes.string,
      }),
    }),
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
}
export default Services
