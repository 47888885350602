import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import className from 'classnames'
import * as style from './hover-plates.module.scss'
import ButtonPrimary from '../../common/buttons/ButtonPrimary'

const LinkWrap = ({ url, isLight, children, isImage }) => {
  const Wrapper = url ? Link : 'div'
  return (
    <Wrapper
      className={className(
        style.card,
        isLight && style.cardLight,
        isImage && style.imageBg
      )}
      to={url}
    >
      {children}
    </Wrapper>
  )
}

const HoverPlate = ({ button, image, title, description, url, isLight }) => {
  const imageData = image && getImage(image.localFile.childImageSharp)
  return (
    <LinkWrap isImage={image} isLight={isLight} url={url}>
      <div className={style.content}>
        {title && <h3 className={style.title}> {title}</h3>}
        <div className={style.contentHidden}>
          {description && <p className="text-20-caption-text-medium">{description}</p>}
          {button && <ButtonPrimary btnClass={style.btn} buttonData={button} />}
        </div>
      </div>
      <div className={className(style.bg, image && style.imageBg)}>
        {image && (
          <GatsbyImage
            width={400}
            height={400}
            image={imageData}
            alt="image.altText"
          />
        )}
      </div>
    </LinkWrap>
  )
}

export default HoverPlate
