import className from 'classnames'
import Fade from 'react-reveal/Fade'
import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import * as section from '../../common/SectionComponent/section-component.module.scss'
import * as style from '../../../../assets/scss/pages/services.module.scss'
import keyGenerator from '../../../utils/keyGenerator'
import LeftBorderTextComponent from '../LeftBorderTextComponent/LeftBorderTextComponent'

const HoverTextComponentSection = ({
  items,
  image,
  title,
  description,
  isLight,
  isWithButton,
}) => {
  const [activeTextItem, setActiveTextItem] = useState(0)
  const activeTextItemToggle = (n) => () => {
    setActiveTextItem(n)
  }
  return (
    <section
      style={{ backgroundImage: `url('${image}')` }}
      className={className(section.section, isLight && section.light, style.bg)}
    >
      <div className="container">
        {title && (
          <Fade bottom>
            <h2 className={section.title}>{title}</h2>
          </Fade>
        )}
        <div className={section.content}>
          <p
            className={className('text-color--primary', style.aboutDescription)}
          >
            {description}
          </p>
          <div className={style.serviceWhyUsItemsBody}>
            {items.map((elem, index) => (
              <Fade bottom key={keyGenerator(index, elem.title)}>
                <LeftBorderTextComponent
                  isLight
                  isPrimary
                  activeItem={activeTextItem}
                  index={index}
                  onHover={activeTextItemToggle}
                  title={elem.title}
                  description={elem.description}
                  button={isWithButton && elem.button}
                />
              </Fade>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
HoverTextComponentSection.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLight: PropTypes.bool,
  isWithButton: PropTypes.bool,
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
}
HoverTextComponentSection.defaultProps = {
  description: '',
  title: '',
  image: '',
  isLight: false,
  isWithButton: false,
}
export default HoverTextComponentSection
