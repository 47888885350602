// extracted by mini-css-extract-plugin
export var animateCircle = "hover-plates-module--animate-circle--o0umA";
export var bg = "hover-plates-module--bg--W8CAq";
export var bounce = "hover-plates-module--bounce--dGMaf";
export var btn = "hover-plates-module--btn--3n5kM";
export var card = "hover-plates-module--card--IzoK8";
export var cardLight = "hover-plates-module--cardLight--ORJBz";
export var content = "hover-plates-module--content--gMOH5";
export var contentHidden = "hover-plates-module--contentHidden--yOmK6";
export var elem = "hover-plates-module--elem--OWdtK";
export var imageBg = "hover-plates-module--imageBg--Enp2L";
export var ldsRing = "hover-plates-module--lds-ring--8A9RK";
export var title = "hover-plates-module--title--HG-d2";
export var wrap = "hover-plates-module--wrap--dP2Fs";