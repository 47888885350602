import className from 'classnames'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'
import * as React from 'react'
import PropTypes from 'prop-types'
import * as style from '../../../../../assets/scss/pages/businesses.module.scss'
import keyGenerator from '../../../../utils/keyGenerator'
import * as section from '../../../common/SectionComponent/section-component.module.scss'
import '../../../../../assets/css/vendors/slick.css'
import '../../../../../assets/css/vendors/slick-theme.css'

const FactsSection = ({ data }) => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    className: 'center',
    centerMode: true,
    centerPadding: '27%',
    slidesToShow: 1,
    speed: 500,
  }
  const FactsData = ({ elem }) => (
    <div className={style.factItem}>
      <div
        className={className(
          style.factItemUnitWrap,
          elem.isUnitNewline && style.factItemUnitNewLine,
          'slick-circle'
        )}
      >
        <p className={style.factItemNumber}>{elem.number}</p>
        <p className={style.factItemUnits}>{elem.units}</p>
      </div>
      <div className={className(style.factItemHidden, 'factItemHidden')}>
        <p className={style.factItemTitle}>{elem.title}</p>
        <p className={style.factItemDescription}>{elem.description}</p>
      </div>
      <div className={style.factItemText}>
        <p className={className(style.factItemTitle, 'slick-title')}>
          {elem.title}
        </p>
        <p
          className={className(style.factItemDescription, 'slick-description')}
        >
          {elem.description}
        </p>
      </div>
    </div>
  )

  return (
    <section className={className(section.section)}>
      <div className="container">
        <Fade bottom>
          <h2 className={section.title}>
            {data.wpPage.page_businesses.factsHeader.title}
          </h2>
        </Fade>
      </div>
      <div className={section.content}>
        <div className="container">
          <p className="text-color__gray--on-dark">
            {data.wpPage.page_businesses.factsHeader.description}
          </p>
          {data.wpPage.page_businesses.factItems.map((elem, index) => (
            <h3
              key={keyGenerator(index, elem.title)}
              className={style.hiddenTitles}
            >
              {elem.title}
            </h3>
          ))}
          <div className={style.facts}>
            {data.wpPage.page_businesses.factItems.map((elem, index) => (
              <div key={keyGenerator(index, elem.title)}>
                <Fade bottom>
                  <FactsData elem={elem} />
                </Fade>
              </div>
            ))}
          </div>
        </div>
        <div className={style.factsSlider}>
          <Slider {...settings}>
            {data.wpPage.page_businesses.factItems.map((elem, index) => (
              <div key={keyGenerator(index, elem.title)}>
                <FactsData elem={elem} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  )
}
FactsSection.propTypes = {
  data: PropTypes.shape({
    wpPage: PropTypes.shape({
      page_businesses: PropTypes.shape({
        factsHeader: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        }),
        factItems: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
          })
        ),
        title: PropTypes.string,
        hiddenTitle: PropTypes.string,
        description: PropTypes.string,
        button: PropTypes.shape({
          text: PropTypes.string,
          query: PropTypes.string,
        }),
      }),
      metaTags: PropTypes.shape({}),
    }),
  }).isRequired,
}
export default FactsSection
